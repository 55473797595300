.consent-popup-button {
  position: relative;
  padding: 10px 20px;
  background-color: transparent;
  color: #bba2e7;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.consent-banner {
  transform: translateY(100%);
  transition: transform 1s ease-in-out;
}