.media-button {
  position: relative;
  padding: 10px 20px;
  background-color: transparent;
  color: #bba2e7;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.media-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  padding: 1.5px;
  background: linear-gradient(to top right, #9067d6, #d2d2d2);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.media-button:hover {
  background-color: #8756db65;
  color: rgba(255, 255, 255, 0.788);
}
