/* General body styles */
body {
    background-color: #000;
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

/* Centered heading */
.text-center {
    text-align: center;
}

/* Padding bottom for headings */
.pb-8 {
    padding-bottom: 2rem; /* 32px */
}

/* Markdown legal document styling */
.MarkdownStyles_markdown-legal__4sC8d {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

/* Lists */
ul {
    list-style-type: none;
    padding-left: 20px;
}

ul li {
    padding-bottom: 10px;
}

/* Links */
a {
    color: #1e90ff; /* Dodger blue for links to stand out */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Sections */
h3 {
    border-bottom: 1px solid #444; /* Subtle line under headings */
    padding-bottom: 10px;
    margin-top: 20px;
    font-size: 1.25rem;
}

/* Responsive design */
@media (max-width: 768px) {
    .MarkdownStyles_markdown-legal__4sC8d {
        width: 95%;
    }
}

