body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    background-color: #000000; /* Background set to black */
    color: #ffffff; /* Text set to white */
    font-size: 18px;
}

.container {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 20px;
}

header {
    background: #000000;
    color: #ffffff;
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
}

header h1 {
    font-size: 2.5em;
    margin: 0;
}

.main {
    padding: 20px 0;
}

.main h1 {
    font-size: 2.2em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.main h2 {
    font-size: 1.8em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.main p {
    margin: 1em 0;
}

.main ul {
    margin: 1em 0;
    padding-left: 20px;
}

.main ul li {
    margin-bottom: 0.5em;
}

footer {
    background: #000000;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #e0e0e0;
    margin-top: 20px;
}

.blog-container {
    width: 100%;
    height: 100vh; /* Adjust height as necessary */
    overflow: hidden;
  }
  
  .blog-container iframe {
    width: 100%;
    height: 100%;
    border: none; /* Removes the border around the iframe */
  }