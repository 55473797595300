@keyframes wiggle {
    0%, 100% {
      transform: rotate(-3deg);
    }
    50% {
      transform: rotate(3deg);
    }
  }
  
  .wiggle-animation {
    animation: wiggle 1s ease-in-out infinite;
  }