.chart {
    border: 2px solid #ccc;
    box-shadow: 4px 4px 10px #888;
    border-radius: 10px;
    overflow: hidden; 
}

.chart:hover {
    opacity: 0.9;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}