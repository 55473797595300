.intro-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: white;
}

.intro-explainer h1{
    font-size: calc(38px + 1.7vmin);
    color: transparent;
    background:linear-gradient(to right, #fffefe, #eeeeee3f);
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}

.intro-explainer-bold span{
    font-size: calc(38px + 1.7vmin);
    color: transparent;
    background: linear-gradient(to right, #aa76d7, #d5c0e8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* -webkit-text-fill-color: transparent; */
}

.intro-explainer-bold h1{
    font-size: calc(38px + 1.7vmin);
    color: transparent;
    background:linear-gradient(to right, #fffefe, #eeeeee3f);
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}

.intro-animation h1{
    font-size: calc(38px + 1.7vmin);
    color: #ffffffca;
}
.intro-explainer h2{
    font-size: calc(15px + 2vmin);
    color: rgb(93, 89, 89);
    background: linear-gradient(to right, #b1aeae, #545353);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.intro-explainer h3{
    font-size: calc(20px + 2vmin);
    color: rgb(93, 89, 89);
    background: linear-gradient(to right, #d2d0d0, #eee2a8ae);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro-animation span{
    background: linear-gradient(to right, #aa76d7, #d5c0e8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
