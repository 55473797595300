@keyframes glow {
    0%,
    100% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 3);
    }
    
    50% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    }
}

.glow-animation {
    animation: glow 1.5s ease-in-out infinite;
}

@media (max-width: 500px) {
    .center-toast {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 90% !important;
        padding-bottom: 10vh !important;
    }
}

